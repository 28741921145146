.drop-btn{
    border: none;
    color : black;
    font-weight: bold;
    font-size: 1.2vw;

}


.drop-open-sitios{
   display: grid;
    grid-template-columns: 1fr  1fr;
    position: absolute;
    right: 10vw;
    background-color: whitesmoke;
    border: 3px solid rgb(0 0 0 / 15%);
    border-radius: 10px;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    z-index: 1;
    margin-top: 10px;
    list-style: none;  
    height: 27vw;
    width: 30vw;
    cursor: pointer;
    transition: 1s;
  
}

.primeros-7{
    height: 26vw;
    width: 15vw;

}

.segundos-6{
    height: 26vw;
    width: 15vw;

   
}

.drop-item-interes{
    height: 4vw;
    width: 13vw;
    display: flex;
    align-items: self-start;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 1vw;
    margin-top: 0.2vw;
   }

.drop-link-interes{
    text-decoration: none;
    font-size: 1vw;
    text-align: start;
    color: rgb(71 70 70);
}

.drop-link-interes:hover{
    color: rgb(28 100 9);
    font-weight: bold;
    scale: 1.1;
}

.drop-closed{
    display: none;
}







