@import url("react-slideshow-image/dist/styles.css"); 

@keyframes appear-from-above {
    from {
        opacity: 0;
        transform: translateY(-100px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}
    

.slide-container-inst{
    animation: appear-from-above 1s;
}

.inst-cont{
    height: 140vw;
    background-color:whitesmoke;
    margin-top: 9.5vw;
}


.inst-section{
    display: grid;
    grid-template-columns: 30% 70%;
   
}

 @keyframes appear-from-left {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
    
 }

.autoridades{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 6vw 2vw ;
    border : 2px solid rgb(71 167 199);
    border-radius: 5px;
    height: 88vw;
    width: 20vw;
    animation: appear-from-left 1s;

}

.titulo{
    width: 20vw;
    height: 5vw;
    background-color: rgb(71 167 199);
    color: rgb(255 255 255);
    font-size: 1.5vw;
    font-weight: bold;
    margin: auto;
}

.titulo h2 {
  margin: auto;
    padding-top: 1vw;
    padding-left: 3vw;
    padding-right: 1vw;

 
}

.presidente{
    border: 1px solid rgb(71 167 199);
    width: 20vw;
    height: 8vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: rgb(255 255 255);
    border-radius: 5px;


}

.presidente h3{
    font-size: 1.5vw;
    font-weight: bold;
    margin:1vw 0;
    padding:  0;
    text-decoration: underline;
}

.presidente p {
    font-size: 1vw;
    font-weight: 500;
    margin: 0 auto;
    width: 18vw;
    text-align: center;

   
}

.sec-general{
    border: 1px solid rgb(71 167 199);
    width: 20vw;
    height: 8vw;
    margin: 0 1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: rgb(255 255 255);
    border-radius: 5px;


}

.sec-general h3{
    font-size: 1.5vw;
    font-weight: bold;
    margin: 1vw 0;
    padding: 0;
    text-decoration: underline;
}

.sec-general p {
    font-size: 1vw;
    font-weight: 500;
    margin: 0;
    padding: 0;
    width: 18vw;
    text-align: center;

   
}

.director-aef{
    border: 1px solid rgb(71 167 199);
    width: 20vw;
    height: 21vw;
    margin: 0  1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: rgb(255 255 255);
    border-radius: 5px;

}

.director-aef h3{
    font-size: 1.5vw;
    font-weight: bold;
    margin: 1.3vw 0;
    padding: 0;
    text-decoration: underline;
}

.director-aef p {
    width: 18vw;
    font-size: 1vw;
    font-weight: 500;
    margin: 0.2vw 0;
    padding: 0;
    text-align: center;
}

.vocales-sup{
    border: 1px solid rgb(71 167 199);
    width: 20vw;
    height: 14vw;
    margin:0 1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: rgb(255 255 255);
    border-radius: 5px;

}

.vocales-sup h3{
    font-size: 1.5vw;
    font-weight: bold;
    margin: 1.6vw 0;
    padding: 0;
    text-decoration: underline;
}

.vocales-sup p {
    font-size: 1vw;
    font-weight: 500;
    margin: 0;
    padding: 0;
    width: 18vw;
    text-align: center;

   
}

.fiscalizadora {
    border: 1px solid rgb(71 167 199);
    width: 20vw;
    height: 16vw;
    margin: 0 1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: rgb(255 255 255);
    border-radius: 5px;

}

.fiscalizadora h3{
    font-size: 1.5vw;
    font-weight: bold;
    margin: 1vw 0;
    padding: 0;
    text-decoration: underline;
}

.fiscalizadora h5 {
    font-size: 1.4vw;
    font-weight: 600;
    margin: 1vw 0;
    padding: 0;
    text-decoration: underline;
}

.fiscalizadora p {
    font-size: 1vw;
    font-weight: 500;
    margin: 0;
    padding: 0;
    width: 18vw;
    text-align: center;

   
}

.suplentes{
    border: 1px solid rgb(71 167 199);
    width: 20vw;
    height: 16vw;
    margin:0  1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: rgb(255 255 255);
    border-radius: 5px;
}

.suplentes h3{
    font-size: 1.5vw;
    font-weight: bold;
    margin:2.2vw 0;
    padding: 0;
}

.suplentes p {
    font-size: 1vw;
    font-weight: 500;
    margin: 0;
    padding: 0;
    width: 18vw;
    text-align: center;

}

@keyframes appear-from-right{
    from {
        opacity: 0;
        transform: translateX(100px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.historia{
    animation: appear-from-right 1s;
    margin : 6vw 2vw;
}

.historia h1{
    font-size: 2vw;
    font-weight: bold;
    margin: 1vw 0;
    padding: 0;
    text-decoration: underline;
    text-align: flex-start;

}

.historia p{
    font-size: 1vw;
    font-weight: 500;


 
}

.leer-mas{
    font-size: 1.3vw;
    font-weight: 500;
    margin: 1vw 0;
    padding: 0;
    text-align: flex-start;
    color: rgb(17 97 124);
    background-color: transparent;
    border: 1px solid rgb(18 177 230);
    cursor: pointer;
    border-radius: 10px;
    width: 10vw;
    height: 3vw;
}

.leer-mas:hover{
    background-color: rgb(18 177 230);
    color: rgb(255 255 255);
}

.inst-section-mobile{
    display: none;
}

/* =============== MOBILE=============== */
@media screen and (width <= 768px) {
    .inst-section{
        display: none;
    }

    .inst-cont{
        height: 380VW;
        background-color: whitesmoke;
        margin-top: 9.5vw;
    }

    .inst-section-mobile{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 6vw 2vw;
    }

    .historia h1{
        font-size: 4vw;
        font-weight: bold;
        margin: 1vw 0;
        padding: 0;
        text-decoration: underline;
        text-align: flex-start;
    
    }

    .historia p{
        font-size: 2vw;
        font-weight: 500;
    
    
     
    }

    .leer-mas{
        font-size: 2vw;
        font-weight: 500;
        margin: 1vw 0;
        padding: 0;
        text-align: flex-start;
        color: rgb(17 97 124);
        background-color: transparent;
        border: 1px solid rgb(18 177 230);
        cursor: pointer;
        border-radius: 10px;
        width: 14vw;
        height: 5vw;
    }

    .leer-mas:hover{
        background-color: rgb(18 177 230);
        color: rgb(255 255 255);
    }

    .autoridades{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 6vw 2vw ;
        border : 2px solid rgb(71 167 199);
        border-radius: 5px;
        height: 170vw;
        width: 45vw;
    
    }

    .titulo{
        width: 45vw;
        height: 12vw;
        background-color: rgb(71 167 199);
        color: rgb(255 255 255);
        font-size: 2.5vw;
        font-weight: bold;
        margin:  auto;
    }

    .titulo h2 {
      margin: auto;
        padding-top: 1vw;
        padding-left: 14vw;
        
     
    }

    .presidente{
        border: 1px solid rgb(71 167 199);
        width: 45vw;
        height: 20vw;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: rgb(255 255 255);
        border-radius: 5px;
    
    
    }

    .presidente h3{
        font-size: 3.2vw;
        font-weight: bold;
        margin:1.3vw 0;
        padding:  0;
        text-decoration: underline;
    }

    .presidente p {
        font-size: 2.6vw;
        font-weight: 500;
        margin: 0 auto;
        width: 28vw;
        text-align: center;
    
       
    }

    .sec-general{
        border: 1px solid rgb(71 167 199);
        width: 45vw;
        height: 15vw;
        margin: 0 1vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: rgb(255 255 255);
        border-radius: 5px;
    
    
    }

    .sec-general h3{
        font-size: 3.2vw;
        font-weight: bold;
        margin: 1.3vw 0;
        padding: 0;
        text-decoration: underline;
    }

    .sec-general p {
        font-size: 2.6vw;
        font-weight: 500;
        margin: 0;
        padding: 0;
        width: 28vw;
        text-align: center;
    
       
    }

    .director-aef{
        border: 1px solid rgb(71 167 199);
        width: 45vw;
        height: 48vw;
        margin: 0  1vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: rgb(255 255 255);
        border-radius: 5px;
    
    }

    .director-aef h3{
        font-size: 3.2vw;
        font-weight: bold;
        margin: 1.5vw 0;
        padding: 0;
        text-decoration: underline;
    }
    
    .director-aef p {
        width: 28vw;
        font-size: 2.6vw;
        font-weight: 500;
        margin: 0.5vw 0;
        padding: 0;
        text-align: center;
    }
    
    .vocales-sup{
        border: 1px solid rgb(71 167 199);
        width: 45vw;
        height: 24vw;
        margin:0 1vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: rgb(255 255 255);
        border-radius: 5px;
    
    }

    .vocales-sup h3{
        font-size: 3.2vw;
        font-weight: bold;
        margin: 1.8vw 0;
        padding: 0;
        text-decoration: underline;
    }

    .vocales-sup p {
        font-size: 2.4vw;
        font-weight: 500;
        margin: 0;
        padding: 0;
        width: 28vw;
        text-align: center;
    
       
    }

    .fiscalizadora {
        border: 1px solid rgb(71 167 199);
        width: 45vw;
        height: 38vw;
        margin: 0 1vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        justify-items: flex-start;
        background-color: rgb(255 255 255);
        border-radius: 5px;
    
    }

    .fiscalizadora h3{
        font-size: 3.2vw;
        font-weight: bold;
        margin: 1vw 0;
        padding: 0;
        text-decoration: underline;
    }

    .fiscalizadora h5 {
        font-size: 2.4vw;
        font-weight: 600;
        margin: 1vw 0;
        padding: 0;
        text-decoration: underline;
    }

    .fiscalizadora p {
        font-size: 2.3vw;
        font-weight: 500;
        margin: 0;
        padding: 0;
        width: 18vw;
        text-align: center;
    
       
    }

    .suplentes{
        border: 1px solid rgb(71 167 199);
        width: 45vw;
        height: 25vw;
        margin:0  1vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: rgb(255 255 255);
        border-radius: 5px;
    }

    .suplentes h3{
        font-size: 3.1vw;
        font-weight: bold;
        margin:2.2vw 0;
        padding: 0;
    }

    .suplentes p {
        font-size: 2.3vw;
        font-weight: 500;
        margin: 0;
        padding: 0;
        width: 18vw;
        text-align: center;
    
    }
}