@import url("react-slideshow-image/dist/styles.css");

.btn-vermas{
    background-color: #4f8a0d;
    color: #fff;
    border: 1px solid #000;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px; 
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    margin-top: 20px;
  position: relative;
  bottom : 6vw;
  right: 19vw


}

.btn-vermas:hover {
  background-color: #f5f5f5;
  color: #000;
}


.nav{
  position: relative;
  z-index: 10;
  bottom: 200vw;
}
.link-style{
  text-decoration: none;
  color: #e2ece2;
  font-size: 1.5vw;

}
.link-style:hover{
  color: #050505;
}
.slide-container-desk ul{
  position: relative;
  bottom: 9vw;
  width: 20vw;
}




@media screen and (width <= 768px) {
  .nav{
    position: relative;
    z-index: 10;
    bottom: 200vw;
  

}
.link-style{
  text-decoration: none;
  color: #161816;
  font-size: 3.5vw;

}


.btn-vermas{
  background-color: #4f8a0d;
  color: #fff;
  border: 1px solid #000;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px; 
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  margin-top: 20px;
position: relative;
bottom : 6vw;
right: 0;
}

}
