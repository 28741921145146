.turismo-cont{
    margin-top: 10vw;
    border: 1px solid rgb(119, 113, 113);
    height: 40vw;
    margin-bottom: 1vw;
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    animation: appear-from-above ease-in 1s;
}
@keyframes appear-from-above{
    from{
        opacity: 0;
        transform: translateY(-50px);
    }

    to{
        opacity: 1;
        transform: translateY(0);
    }
}
.turismo-cont h1{
    font-size: 2.3vw;
    color: rgb(119, 113, 113);
    text-align: center;
    margin-top: 1vw;
    font-weight: 800;
}
.primer-p{
    font-size: 1.2vw;
    color: rgb(119, 113, 113);
    text-align: center;
    margin-top: 1vw;
    font-weight: 800;
}
.segundo-p{
    font-size: 1.2vw;
    color: rgb(119, 113, 113);
    text-align: center;
    margin-top: 1vw;
    font-weight: 800;
}
.turismo-cont ul li{
    list-style: none;
  
    margin-top: 10vw;
    font-size: 1.2vw;
    color: rgb(119, 113, 113);
    border: 1px solid rgb(119, 113, 113);
    width: 12vw;    
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    height: 12vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding:0.5vw 2vw;
    text-align: center;
    margin-left: 0.8vw;
    margin-right: 0.8vw;



}
.turismo-cont ul li:hover{
    background-color: rgb(65, 153, 31);
    color: white;
    scale: 1.1;
    transition: 0.5s;
}

.pastillas {
    display: flex;
 justify-content: space-between;
    margin-top: 15vw;
    margin-bottom: 1vw;
    margin-left: 1vw;
    margin-right: 1vw;
   
}
.pastilla{
    width: 15vw;
    height: 6vw;
    border: 1px solid rgb(119, 113, 113);
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    display: flex;
    flex-direction: column;
    justify-content: center;

 

}

.pastilla p{
    font-size: 0.8vw;
    color: rgb(119, 113, 113);
    text-align: center;
    font-weight: 800;
    margin: 0;
    padding: 0.5vw;
}
.pastilla p a {
    color: green;
}



@media screen and (width <= 768px) {
    .turismo-cont{
        margin-top: 22vw;
        height: 380vw;
     
    }
    .turismo-cont h1{
        font-size: 10vw;
        font-weight: 600;
        color: #817a7a;
        margin-bottom: 1vw;
    }
    .primer-p{
        font-size: 4vw;
        font-weight: 400;
        color: #817a7a;
        margin-top: 3vw;
        margin-bottom: 3vw;
        text-align: center;
    }
    .segundo-p{
        font-size: 4vw;
        font-weight: 400;
        color: #817a7a;
        margin-top: 3vw;
        margin-bottom: 3vw;
        text-align: center;
    }
    .turismo-cont ul{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 1vw;
        height: auto;
        
    }
    .turismo-cont ul li{
        margin-top: 10vw;
        font-size: 4vw;
        color: #817a7a;
        border: 1px solid #817a7a;
        width: 55vw;    
        border-radius: 10px;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        height: 30vw;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding:0.5vw 2vw;
        text-align: center;
     
    }
    .pastillas {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15vw;
        margin-bottom: 1vw;
        margin-left: 1vw;
        margin-right: 1vw;
        height: auto;
       
    }
    .pastilla{
        width: 32vw;
        height: 23vw;
        border: 1px solid #817a7a;
        border-radius: 10px;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    
     
    
    }
    .pastilla p{
        font-size: 2.6vw;
        color: rgb(119, 113, 113);
        text-align: center;
        font-weight: 800;
        margin: 0;
        padding: 0.5vw;
    }
}