.constancia-cont{
    height: 35vw;
    width: 90%;
   border: 1px solid rgb(126, 133, 126);
   border-radius: 10px;
   box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%);
   margin-top: 10vw;
   display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1vw;
    animation: appear-from-right ease-in 1s;
}
@keyframes appear-from-right{
    from{
        opacity: 0;
        transform: translateX(50px);
    }
    to{
        opacity: 1;
        transform: translateX(0);
    }
}


.constancia-cont h2{
    font-size: 3vw;
    color: rgb(119, 122, 117);
    margin: 0 3vw;
    padding:  3vw;
}
.constancia-cont p{
    font-size: 1.8vw;
    text-align: justify;
    margin: 3vw 3vw;
    padding: 0;
    color:rgb(119, 122, 117);
}

.constancia-logo{
  
    width: 10vw;
    height: 10vw;
}
.constancia-text{
    display: flex;
    margin-left: 2vw;
    margin-right: 2vw;
}
.constancia-text p{
    font-size: 2vw;
    text-align: justify;
    margin: 0 1vw;
    padding: 0;
    color:rgb(119, 122, 117);
}

@media screen and (width <= 768px) {
    .constancia-cont{
        height: 100vw;
        width: 90%;
       border: 1px solid rgb(126, 133, 126);
       border-radius: 10px;
       box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 75%);
       margin-top: 24vw;
     
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1vw;
     
    }
    .constancia-cont h2{
        font-size: 7vw;
        color: rgb(119, 122, 117);
        margin: 0 3vw;
        padding:  2vw;
    }
    .constancia-cont p{
        font-size: 4vw;
        text-align: justify;
        margin: 3vw 3vw;
        padding: 0;
        color:rgb(119, 122, 117);
    }
    .constancia-logo{
        display: none;
    }

}