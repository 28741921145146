.servicios-mob{
    height: 160vw;
    width: 90%;
    margin-left: auto;
    margin-right: auto;



  
}

.servicios-mob ul{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: 5vw 0;
}

.servicios-mob a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 15vw;
    width: 70vw;
    background-color: rgb(255 255 254);
    font-size: 3vw;
    border: 1px solid rgb(15 172 15);
    border-radius: 5px;
    text-decoration: none;
    color: rgb(21 145 5);
    margin: 0.5vw 0;
}

.servicios-mob a:hover{
    color: white;
    background-color:  rgb(21 145 5);;
}
