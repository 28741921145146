.contacto-cont {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 60vw;
    width: 100%;
}

.contacto-left {
    height: 60vw;
    width: 45vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    padding: 0 2rem;
    line-height: 1.5;
    letter-spacing: 1px;
    border: 1px solid #a6aca4;
}

@keyframes appear-from-left {
    from {
        opacity: 0;
        transform: translateX(-5rem);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.contacto-left form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    animation: appear-from-left 1s ease-in-out;
}

.contacto-left form input {
    width: 70%;
    height: 0.8vw;
    padding: 1rem;
    margin: 1rem 0;
    border: 1px solid #237a12;
    border-radius: 5px;
    outline: none;
    font-size: 1.1vw;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
    font-family: Roboto, sans-serif;
}

.contacto-left form textarea {
    width: 70%;
    padding: 1rem;
    margin: 1rem 0;
    border: 1px solid #237a12;
    border-radius: 5px;
    outline: none;
    font-size: 1.1vw;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
    font-family: Roboto, sans-serif;
}

.contacto-left form button {
    width: 30%;
    padding: 1rem;
    margin: 1rem 0;
    border: 1px solid #125005;
    border-radius: 5px;
    outline: none;
    font-size: 1.2vw;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: #237a12;
    color: #fff;
    cursor: pointer;
}

.contacto-left form button:hover {
    background-color: #fff;
    color: #237a12;
    transition: all 0.5s ease;
}

.contacto-right {
    height: 60vw;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1.2vw;
    margin-top: 10vw;

}

.divider {
    width: 100%;
    height: 5vw;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #a6aca4;
    margin: 0;
    padding: 0;
}

.comun {
    font-size: 1vw;
    font-weight: 600;
    text-align: flex-start;
    margin: 0 1vw;
    padding: 0;
    color: #7e857d;
}

.resaltado {
    font-size: 1.2vw;
    font-weight: 600;
    text-align: flex-start;
    margin: 0 1vw;
    padding: 0;
    color: #237a12;
}



@media screen and (width <=768px) {
    .contacto-cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 250vw;
        margin-top: 4vw
    }

    .contacto-left {
        height: 115vw;
        width: 56vw;
        animation: appear-from-left 1s ease-in-out;
    }

    .contacto-left form input {
        width: 70%;
        font-size: 3.5vw;


    }

    .contacto-left form textarea {
        width: 70%;
        font-size: 3.5vw;

    }

    .contacto-left form button {
        width: 60%;
        font-size: 2.8vw;

    }

    .contacto-right {
        height: 80vw;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        justify-items: center;
        font-size: 1.2vw;
        margin-top: 15vw;

    }

    .divider {
        width: 100%;
        height: 33vw;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #a6aca4;
        margin: 0;
        padding: 0;
    }

    .comun {
        font-size: 3vw;
        font-weight: 600;
        text-align: center;
        margin: 0 3vw;
        padding: 0;
        color: #7e857d;
    }

    .resaltado {
        font-size: 3vw;
        font-weight: 600;
        text-align: center;
        margin: 0 1vw;
        padding: 0;
        color: #237a12;
    }



}