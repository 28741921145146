@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@500&family=Ubuntu:wght@400;500&display=swap');

body{
   margin: 0;
    padding: 0;
    font-family: Heebo, sans-serif;

}

.foto{
 
    height: 30vw;
    width: 30vw;
}



.content{
   border: 1px solid #046d00;

}
.subtitulo{
    font-size: 1vw;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    text-align: start;
    padding-left: 1vw;

}
.parrafo-1{
    font-size: 1vw;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    padding-left: 1.4vw;
    padding-right: 1.4vw;
    text-align: justify;
    
}

.lista{
    font-size: 1vw;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    padding-left: 1.4vw;
    padding-right: 1.4vw;
    text-align: justify;
}
.pares{
    display: grid;
    grid-template-columns: 30% 70%;
    padding-right: 1.4vw;
}
.fecha{
    font-size: 1vw;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 800;
    padding-left: 1.4vw;
    padding-right: 1.4vw;
    text-align: end;
}
.directivos {
    display: grid;
    grid-template-columns: 50% 50%;
    padding-right: 1.4vw;
}
