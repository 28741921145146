.footer-container {
    background-color: rgb(56 114 56);
    height: 25vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.newsletter {
    border: 2 px solid black;
    background-color: rgb(12 151 7);
}

.contact {
    background-color: rgb(12 151 7);
}

.contact ul {
    border: 2 px solid black;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 0.2vw;

}

.contact li {
    border: 1px solid rgb(169 206 145);
    border-radius: 4px;
    width: 17vw;
    height: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    color: white;
    font-size: 1.3vw;
    font-weight: bold;
    margin: auto;

}

.form{
    margin: 0;
   position: relative;
    top: -1.2vw;
    
   
}

.input {
    width: 30%;
    height: 2vw;
    border: 2px solid black;
    background-color: rgb(255 255 255);
    border-radius: 8px;
    margin-left: 1vw;
  
}

.btn-boletin {
    background-color: rgb(255 255 255);
    border: 2px solid black;
    width: 16%;
    height: 2.6vw;
    margin-left: 1vw;
    margin-top: 1vw;
    font-size: 1vw;
    font-weight: bold;
    color: rgb(0 0 0);
    cursor: pointer;
    transition: 0.5s;
    border-radius: 8px;

}

.btn-boletin:hover {
    background-color: rgb(0 0 0);
    color: rgb(255 255 255);
}

.boletin-h1 {
    font-size: 1.5vw;
    font-weight: bold;
    color: rgb(255 255 255);
    margin-top: 1vw;
    margin-left: 1.3vw;
}

.boletin-p {
    font-size: 1vw;
    font-weight: bold;
    color: rgb(255 255 255);
    margin-top: -1vw;
    margin-left: 1.3vw;
}


.phone {
    width: 2.9vw;
   
}

.phone:hover {
    scale: 1.2;
    transition: 0.5s;
}


.google {
    width: 2vw;
   
}

.google:hover {
    scale: 1.2;
    transition: 0.5s;
}

.instagram {
    width: 4vw;
    height: 4vw;
    cursor: pointer;
}

.instagram:hover {
    scale: 1.3;
    transition: 0.5s;
}

.contact-insta {
    margin: 0.5vw auto;
}

.contact-whats {
    margin: 0 auto;
    padding-top: 1vw;
}

.face {
    width: 2.9vw;
    margin-top: 1.4vw;
    cursor: pointer;

}

.face:hover {
    scale: 1.3;
    transition: 0.5s;
}

.whats {
    width: 2.7vw;
    cursor: pointer;

}

.whats:hover {
    scale: 1.3;
    transition: 0.5s;
}

.mail {
    width: 2.7vw;
    cursor: pointer;
}

.mail:hover {
    scale: 1.3;
    transition: 0.5s;
}

.direccion {
    font-size: 1.1vw;
    font-weight: bold;
    color: rgb(255 255 255);
    margin-top: 1vw;
    margin-left: 1vw;
}

.tel-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;


}

.telefonos {
    display: block;
    font-size: 1.1vw;
    font-weight: bold;
    margin: 0;



}

.tel-p {
    font-size: 1.1vw;
    font-weight: bold;
    margin: 0;
}

.horarios{
    border: 2px solid rgb(173 209 172);
    width: 20vw;
    height: 10vw;
    margin: 1vw;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    color: white;
    font-size: 1.3vw;
    font-weight: bold;
    position: relative;
    top: -0.9vw;
  
}

.horarios-h3{
    font-size: 1.3vw;
    font-weight: bold;
    color: rgb(255 255 255);
    margin-top: 1vw;
    margin-left: 1.3vw;
}

.horarios-p{
    font-size: 1.1vw;
    font-weight: bold;
    color: rgb(255 255 255);
    margin-top: -1vw;
    margin-left: 1.3vw;
}

/* MOBILE -------------------------------------------- */

@media screen and (width <= 768px) {
    .footer-container {
        height:290vw;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgb(12 151 7);
       
        
    }

    .newsletter{
        width: 100%;
        height: 110vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgb(12 151 7);
        margin: 0;
        position: relative;
        top: 31.8vw;
    }

    .boletin-h1 {
        font-size: 7vw;
        font-weight: bold;
        color: rgb(255 255 255);
        margin-left: 1.3vw;
        margin-top: 4vw;
        margin-bottom: 8vw;
    }

    .boletin-p {
      display: none;
    }

    .form{
    display: flex;
flex-direction: column; 
align-items: center;
justify-content: center; 
justify-items: center;     
       
    }

    .input {
        width: 70vw;
        height: 6vw;
        border: 2px solid black;
        background-color: rgb(255 255 255);
        border-radius: 8px;
        margin-left: 1vw;
        margin-top: 3vw;
        margin-bottom: 3vw;
        font-size: 3vw;
        font-weight: bold;
        color: rgb(0 0 0);
    }

    .btn-boletin {
        background-color: rgb(255 255 255);
        border: 2px solid black;
        width: 30vw;
        height: 6vw;
        margin-left: 1vw;
        margin-top: 3vw;
        margin-bottom: 3vw;
        font-size: 3vw;
        font-weight: bold;
        color: rgb(0 0 0);
        cursor: pointer;
        transition: 0.5s;
        border-radius: 8px;
    
    }
    
    .btn-boletin:hover {
        background-color: rgb(0 0 0);
        color: rgb(255 255 255);
    }

    .horarios{
        border: 2px solid rgb(173 209 172);
        width: 60vw;
        height: 30vw;
        margin: 1vw;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        color: white;
        font-size: 1.3vw;
        font-weight: bold;
        position: relative;
        top: -0.9vw;
        justify-content: center;
        align-items: center;
        justify-items: center;
      
    }
    
    .horarios-h3{
        font-size: 3.7vw;
        font-weight: bold;
        color: rgb(255 255 255);
        margin-top: 1vw;
        margin-left: 1.3vw;
    }
    
    .horarios-p{
        font-size: 3.2vw;
        font-weight: bold;
        color: rgb(255 255 255);
        margin-top: -1vw;
        margin-left: 1.3vw;
    }

    .contact{
        width: 100%;
        height: 130vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgb(12 151 7);
        margin-top:  31.32vw;
        
   
    }

    .contact ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        justify-items: center;
        height: 140vw;
      
    
    
    }
    
    .contact li {
        height: 70vw;
       border-top: 1px solid white;
       border-right: none;
     border-left: none;
        border-bottom: none;
    border-radius: 0;
    width: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    color: white;
    font-size: 3vw;
    font-weight: bold;
    margin: auto;

   
    
    }

    .phone {
        width: 8vw;
        margin-top: 2vw;
       
    }

    .google {
        width: 6.5vw;
        margin-top: 3vw;
       
    }

    .instagram {
        width: 12vw;
        height: 12vw;
        cursor: pointer;
    }



    .face {
        width: 8vw;
        margin-top: 6vw;
        cursor: pointer;
    
    }

    .whats {
        width: 8vw;
        cursor: pointer;
    
    }

    .mail {
        width:  8vw;
        cursor: pointer;
    }
        
    
    .telefonos {
        display: block;
        font-size: 3vw;
        font-weight: bold;
        margin: 0;
    
    
    
    
    }
    
    .tel-p {
        font-size: 3vw;
        font-weight: bold;
        margin: 0;
    }

    .direccion {
        font-size: 3vw;
        font-weight: bold;
        color: rgb(255 255 255);
        margin-top: 1vw;
        margin-left: 1vw;
    }
    
    
}