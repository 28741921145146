.propios-container{
    margin-top: 10vw;
    height: 50vw;


}

.propios-section{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 50vw;
    width: 100vw;
}

@keyframes slide-in-from-left {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
    

.propios-h1{
    font-size: 1.8vw;
    font-weight: bold;
    margin-bottom: 2vw;
    margin-top: 3vw;
    text-align: center;
    color: rgb(128 124 124);
    animation : 1s ease-out 0s 1 slide-in-from-left;
}

 @keyframes slide-in-from-right {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }

    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

.propios-text{
    width: 30vw;
    height: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 3vw;
   border-bottom: 1px solid rgb(201 192 192);
   animation : 1s ease-out 0s 1 slide-in-from-right;
}

.propios-text h3{
    font-size: 1.5vw;
    font-weight: bold;
    margin-bottom: 2vw;
    margin-top: 3vw;
    text-align: center;
    color: rgb(128 124 124);
}

.propios-text p {
    font-size: 1vw;
    font-weight: bold;
    margin-bottom: 2vw;
    margin-top: 3vw;
    color: rgb(128 124 124);
    text-align: center;
} 

.slide-container-propios{
    width: 60vw;
    height: 50vw;
    position: relative;
    left: 10vw;
    top: 6vw;
  
    
}

@media screen and (width <= 768px) {
    .propios-container{
        margin-top: 25vw;
        height: 140vw;
    
    
    }
  
    .propios-section{
        display: flex;
        flex-direction: column;
        height: 100vw;
        width: 100vw;
        
    }

    .propios-h1{
        font-size: 5vw;
        font-weight: bold;
        margin-bottom: 2vw;
        margin-top: 3vw;
        text-align: center;
        color: rgb(128 124 124);
    }

    .propios-text{
        width: 100vw;
        height:50vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       border-bottom: 1px solid rgb(201 192 192);
    }

    .propios-text h3{
        font-size: 4vw;
        font-weight: bold;
      margin: 3vw;
        text-align: center;
        color: rgb(128 124 124);
    }

    .propios-text p {
        font-size: 2.5vw;
        font-weight: bold;
       margin: 3vw;
        color: rgb(128 124 124);
        text-align: center;
    } 

    .slide-container-propios{
        width: 60vw;
        height: 40vw;
        position: relative;
        left: 23vw;
        top: 0;
      
        
    }

}