.visita-cont{
    margin-top: 10vw;
    border: 1px solid rgb(119, 113, 113);
    height: 60vw;
    margin-bottom: 1vw;
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    animation: appear-from-above ease-in 1s;
}
@keyframes appear-from-above{
    from{
        opacity: 0;
        transform: translateY(-50px);
    }

    to{
        opacity: 1;
        transform: translateY(0);
    }
}
.visita-cont h1{
    font-size: 2.3vw;
    color: rgb(119, 113, 113);
    text-align: center;
    margin-top: 3vw;
    font-weight: 800;
    line-height: 1.3;
    margin-bottom: 5vw;
}
.youtube{
 display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5vw;
    margin-bottom: 2vw;

}

@media screen and (width <= 768px) {
    .visita-cont{
        margin-top: 22vw;
      
        height: 150vw;
    }
    .visita-cont h1{
        font-size: 8vw;
        text-align:center ;
        line-height: 1.3;
    }
    .youtube{
        display: flex;
        flex-direction: column;
        justify-content: center;

        align-items: center;

        margin-top: 2vw;
        margin-bottom: 2vw;
        margin-right: auto;
        margin-left: auto;
        width: 100%;

    }
    .video{
        width: 100%;
        height: 100%;
  
    }
}