.drop-btn{
    border: none;
    color : rgb(31, 32, 31);
    font-weight: bold;
    
    

}
.drop-span{
    position: relative;
    left: 0.5vw;

}

.drop-open{
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: whitesmoke;
    border: 3px solid rgb(0 0 0 / 15%);
    border-radius: 10px;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    z-index: 1;
    margin-top: 10px;
    list-style: none;  
    height: 25vw;
    font-size: 0.95vw;
    width: 18vw;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    font-weight: bold;
}

.drop-closed{
    display: none;
}



 .drop-item{
  color: rgb(0 0 0);
  margin: 0;
width: 10vw;

}

.drop-link{
    text-decoration: none;
  color: black;
    cursor: pointer;
    font-size: 1vw;
    font-weight: bold;
}

.drop-link:hover{
    color: rgb(100 209 12 / 75.3%);
}