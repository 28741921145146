.navbar-container {
  height: 9.5vw;
  border-bottom: 1px solid #0e0d0d;
  position: fixed;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: whitesmoke;
}

.navbar-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2vw;
  padding: 0 2vw;
  width: 100%;
}


.logo {
  width: 7vw;
  height: 7vw;
  margin-right: 2vw;
}

.navbar-logo {
  margin-right: 3vw;
  display: flex;
  width: 30vw;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-weight: bold;

}

.navbar-logo p {
  margin: 0;
  font-size: 1.6vw;
  line-height: 1.8vw;

}


ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 6vw;
  margin: auto;
}

.item-list {
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  color: black;
  padding: 0.5vw 1vw;
  display: flex;
  justify-content: space-around;
  font-size: 1.1vw;
  line-height: 1.2vw;
}

.item-list:hover {
  color: rgb(30 192 38);
}

.drop {
  margin-right: 0.8vw;
}

.item-list-mobile {
  display: none;
}

.navbar-mobile-btns {
  display: none;
}

.brand a{
  text-decoration: none;
  color: black;
}



/* MOBILE VIEW ---------------------------------------------------------------- */


@media screen and (width <= 768px) {
  .navbar-container {
    height: auto;
    width: 100%;
 
    

  }

  .navbar-logo {
    width: 50vw;
    margin-right: 0;
  }

  .logo {
    width: 15vw;
    height: 15vw;

  }

  .navbar-links {
    display: none;

  }

  .navbar-logo p {
    margin: 0;
    font-size: 3.2vw;
    line-height: 3.5vw;
  }

  .item-list {
    display: none;
  }

  .navbar-mobile-btns {
    display: block;
    margin-right: 2vw;
  }

.hamb {
    margin-left: 18vw;
    margin-top: 2vw;
    width: 8vw;
    height: 8vw;
    border: none;
    background-color: transparent;

  }

  .ul-list-mobile {
    display: flex;
    flex-direction: column;
    height: 150vw;
    width: 100%;
    position: absolute;
    top: 22vw;
    left: 0;
    top: 20vw;
    background-color: rgb(19 95 17);
    margin: auto;
    z-index: 100;
    touch-action: pan-y; 
    overflow-y: auto;
 }

  .item-list-mobile {
border-bottom: 2px solid #e0d5d5;
text-decoration: none;
color: rgb(247 223 223);
width: 100%;
height: 15vw;
display: flex;
justify-content: center;
align-items: center;

  }

  .item-list-mobile:hover {
  background-color: whitesmoke;
  color: rgb(19 95 17);
  }

.item-list-mobile a {
  text-decoration: none;
  color: rgb(247 223 223);
  width: 100%;
  height: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  }

  .item-list-mobile a:hover {
    color: rgb(19 95 17);
  }

.ul-closed{
    display: none;
  }


}