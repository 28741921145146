
.asociarse-cont{
    margin: 9.8vw 1vw 0.5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
  border: 1px solid rgb(77 83 75);
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    animation: appear-from-left 1s ease-in-out;


}

@keyframes appear-from-left {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

.asociarse-cont h1{
font-size: 2.4vw;
font-weight: 600;
color: rgb(105 112 102);

}

.asociarse-cont h2{
font-size: 1.8vw;
font-weight: 600;
color: rgb(105 112 102);
margin-bottom: 1vw;
margin-left: 1vw;
}

.asociarse-cont p{
font-size: 1.2vw;
font-weight: 400;
color: rgb(105 112 102);
margin-left: 1vw;
margin-right: 1vw;
margin-bottom: 1vw;
}

@media screen and (width <=768px) {
.asociarse-cont{
    margin: 23.5vw 1vw 0.5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
  border: 1px solid rgb(77 83 75);
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);


}

.asociarse-cont h1{
font-size: 6vw;
font-weight: 600;
color: rgb(105 112 102);

}

.asociarse-cont h2{
font-size: 5vw;
font-weight: 600;
color: rgb(105 112 102);
margin-bottom: 1vw;
margin-left: 1vw;
}

.asociarse-cont p{
font-size: 3.5vw;
font-weight: 400;
color: rgb(105 112 102);
margin-left: 1vw;
margin-right: 1vw;
margin-bottom: 1vw;
}

}

