.novedades-cont{
    border: 1px solid rgb(119, 113, 113);
    margin-top: 10vw;
    height: 30vw;
    margin-bottom: 1vw;
    width: 95%;
    margin-right: auto; 
    margin-left: auto;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    display: flex;
    flex-direction: column;
    animation: appear-from-below 1s ease-in-out; 
}
@keyframes appear-from-below{
    from{
        opacity: 0;
        transform: translateY(100%);
    }
    to{
        opacity: 1;
        transform: translateY(0%);
    }
}
.novedades-cont h1{
    margin-top: 1vw;
    margin-left: 1vw;
    font-size: 2.4vw;
    font-weight: bold;
    color: rgb(119, 113, 113);
    align-self: center;
}   

@media screen and (width <= 768px) {
    .novedades-cont{
        height: 80vw;
     margin-top: 24vw;
    }
    .novedades-cont h1{
        font-size: 5vw;
    }
}