@keyframes appear-from-above{
    from{
        opacity: 0;
        transform: translateY(-50px);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
}

.gerdanna-cont{

    margin-top: 9.7vw;
    display: grid;
    grid-template-columns: 40% 60%;
    animation : appear-from-above 1s ease-in;
    border: 1px solid #aca9a9;
    height: 38vw;
    margin-bottom: 1vw;
    margin-left: 2vw;
    margin-right: 2vw;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #817a7a;
    width: 96%;

}

.slide-gerdanna{

    height: 30vw;
    margin-bottom: 10vw;
}
.slide-gerdanna ul{
    position: relative;
    bottom: 8vw;
    left: 5vw;
}

.gerdanna-h1{
    font-size: 1.8vw;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 19vw;
    margin-left: 2vw;
  
    text-align: center;
    color: #817a7a;
    line-height: 4.5vw;

    
}
.gerdanna-h1 h1{
    font-size: 2vw;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vw;
    margin-left: 2vw;
  
    text-align: center;
    color: #817a7a;
    line-height: 4.5vw;

    

}
.gerdanna-h1 h3{
    color: rgb(36, 34, 34);
}

@media screen and (width <=768px) {

    .gerdanna-cont{
        height: 170vw;
        margin-top: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    }
    .slide-gerdanna{

        height: 60vw;

        width: 75%;
        border:  3px solid #fdf4f4;
    }
    .slide-g{
        height: 60vw;
        width: 75%;
        border:  3px solid #817a7a;
        background-color: black;
    }

    .gerdanna-h1 h1{
       
        font-size: 6vw;
        margin-left: auto;
        margin-right: auto;
        line-height: 8vw;

    }   

    .gerdanna-h1 h3{
       
        font-size: 6vw;

    }
}