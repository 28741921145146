.subsidio-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    background-color: #F2F2F2;
   border: 1px solid #817a7a;
    border-radius: 10px;
    padding: 20px;
   margin: 9.8vw auto 0.5vw;
    box-shadow:  0 0 5px 0 rgb(0 0 0 / 75%);
    animation: appear-from-above ease-in 1s;
}

@keyframes appear-from-above{
    from{
        opacity: 0;
        transform: translateY(-50px);
    }

    to{
        opacity: 1;
        transform: translateY(0);
    }
}

.subsidio-cont h1{
    font-size: 2.8vw;
    font-weight: 600;
    color: #817a7a;
    margin-bottom: 1vw;
}

.subsidio-cont h3{
    font-size: 1.8vw;
    font-weight: 600;
    color: #817a7a;
    margin-bottom: 1vw;
}

.subsidio-cont p{
    font-size: 1.2vw;
    font-weight: 400;
    color: #817a7a;
    margin-bottom: 1vw;
    text-align: center;
}

@media screen and (width <= 768px) {
    .subsidio-cont{
        margin-top: 19vw;
        height: 140vw;

    }

    .subsidio-cont h1{
        font-size: 10vw;
        font-weight: 600;
        color: #817a7a;
        margin-bottom: 8vw;
    }

    .subsidio-cont h3{
        font-size: 6vw;
        font-weight: 600;
        color: #817a7a;
        margin-bottom: 8vw;
    }

    .subsidio-cont p{
        font-size: 4vw;
        font-weight: 400;
        color: #817a7a;
        margin-bottom: 8vw;
        text-align: center;
        margin-right: 6vw;
        margin-left: 6vw;
    }



}