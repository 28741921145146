.de-interes-cont{
    height: 150vw;
    width: 90%;
    margin: auto;
}
.de-interes-cont ul{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: 5vw 0;
}
.de-interes-cont a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20vw;
    width: 70vw;
    background-color: rgb(255 255 254);
    font-size: 3.2vw;
    border: 1px solid rgb(15 172 15);
    border-radius: 5px;
    text-decoration: none;
    color: rgb(21 145 5);
    margin: 0.5vw 0;
}
.de-interes-cont a:hover{
    color: white;
    background-color:  rgb(21 145 5);;
}
