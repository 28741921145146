.cont-urgencias{
    width: 100%;
    height: 50vw;
    margin-top: 10vw;
    background-color: whitesmoke;
}

.cont-urgencias h1{
    font-size: 3vw;
    font-weight: 500;
   margin-left: 1vw;
   margin-top: 2vw;
   margin-bottom: 0;
    color: rgb(78 75 75);
}

.divisor-urg{
    width: 100%;
    height: 1px;
    background-color: rgb(65 62 62);
    margin: 1vw 0;
}

.second-cont-urg{
    display: grid;
    margin-top: 0;
    grid-template-columns: 1fr 1fr;
    height: 30vw;

}

.right-urg{
    height: 30vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@keyframes appear-from-left {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}
    

.left-urg{
    background-color: whitesmoke;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1vw;
    animation: appear-from-left 1s;

}

.left-urg ul{
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    width: 100%;
}

.left-urg li {
  list-style-type: disc;
    padding: 0;
    font-size: 1.0vw;
    font-weight: 400;
    color: rgb(117 116 116);
    margin: 1vw 0;
}

.left-urg p {
    padding: 0;
    font-size: 1.0vw;
    font-weight: 600;
    color: rgb(117 116 116);
    margin: 1vw;
}


@keyframes appear-from-right {
    from {
        opacity: 0;
        transform: translateX(100px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.ambulancia-cont{
    margin: auto;
    animation: appear-from-right 1s;

  
}
    
.ambulancia{
    width: 40vw;
}

/* ----------MOBILE------------------------------------------------- */

@media screen and (width <= 768px) {
    .cont-urgencias{
        width: 100%;
        height: 110vw;
        margin-top: 28vw;
        background-color: whitesmoke;
    }

    .cont-urgencias h1{
        font-size: 6vw;
        font-weight: 500;
        text-align: center;
       margin-bottom: 0;
        color: rgb(78 75 75);
    }

    .second-cont-urg{
        display: flex;
     flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 12vw;
        height: 70vw;
    }

    .left-urg{
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 1vw;
        animation: appear-from-left 1s;
    
    }

    .urg-ps{
        margin: auto;
        width: 80%;
    }

    .left-urg p {
        padding: 0;
        font-size: 2.6vw;
        font-weight: 600;
        color: rgb(117 116 116);
        margin: 1vw;
    }

    .left-urg ul{
        margin: auto;
        width: 80%;
    }

    .left-urg li {
        list-style-type: disc;
          padding: 0;
          font-size: 2.5vw;
          font-weight: 400;
          color: rgb(117 116 116);
          margin: 1vw 0;
      }

      .ambulancia{
        width: 80vw;
        background-color: whitesmoke;

      }


}
