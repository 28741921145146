.sitios-mob{
    height: auto;
    width: 90%;
    margin: 25vw auto 1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 75%);

}

.sitios-mob ul{
    height: 220vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5vw;

}

  .sitios-mob li{
    width: 90%;
    display: flex  ; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5vw;
    margin-bottom: 5vw;
    text-align: center;
  border-bottom: 1px solid #83a379;
  } 
   
    
  
    .sitios-mob li a{
      text-decoration: none;
      color: #000;
      font-size: 5vw;
      font-weight: bold;
    }

    .sitios-mob li a:hover{
      color: #2d940e;
      scale: 1.1;
    }
    
