
.drop-btn:hover{
    color: rgb(30 192 38);
    background-color: transparent;
}


.drop-open-2{
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 1vw;
    background-color: whitesmoke;
    border: 3px solid rgb(0 0 0 / 15%);
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    border-radius: 10px;
    z-index: 1;
    margin-top: 10px;
    list-style: none;  
    height: 25vw;
    font-size: 0.95vw;
    width: 18vw;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    font-weight: bold;
}

.drop-closed{
    display: none;
}



 .drop-item{
  color: rgb(0 0 0);
  margin: 0;
width: 10vw;

}

.drop-item:hover{
    color: rgb(30 192 38);
    background-color: transparent;
   
   

} 