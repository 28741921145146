.sepelios-cont{
margin-top: 9.5vw;
height: auto;
border: 2px solid #918d8d;
box-shadow: 0 0 5px 0 rgb(0 0 0 / 75%);
display: grid;
grid-template-columns: 56% 44%;
    
}

@keyframes appear-from-left{
    from{
        opacity: 0;
        transform: translateX(-20px);
    }

    to{
        opacity: 1;
        transform: translateX(0);
    }
}

.sepelios-left{
    height:80vw;
    border-radius: 10px;
    animation: appear-from-left 1s;
}

.sepelios-left h3{
    font-size: 1.5vw;
    margin: 0;
    padding: 0;
    margin-left: 1vw;
    margin-top: 1vw;
}

.sepelios-left p{
    font-size: 1.1vw;
    margin: 0;
    padding: 0;
    margin-left: 1vw;
    margin-top: 1vw;
    color: rgb(116 107 107);
    font-weight: 400;
}

.sepelios-left li{
    font-size: 1vw;
    margin: 0;
    padding: 0;
    margin-left: 1vw;
    margin-top: 1vw;
    color: rgb(116 107 107);
    font-weight: 400;
}

@keyframes appear-from-right{
    from{
        opacity: 0;
        transform: translateX(20px);
    }

    to{
        opacity: 1;
        transform: translateX(0);
    }
}

.sepelios-right{
    height: auto;
    animation: appear-from-right 1s;
}

.norte{
    height: 70vw;
    width: 42vw;
    margin: 1vw;
    border: 1px solid rgb(177 165 165);
    border-radius: 5px;
}

.lista-int{
    list-style: upper-alpha;
}

.panteones{
    height:120vw;
    align-items: center;
    justify-content: center;
    justify-items: center;  
    width: 95%;
    border: 1px solid rgb(177 167 167);
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 75%);
    margin: auto;
    margin-top: 1vw;
    border-radius: 10px;
}

.panteones-title{
    font-size: 1.8vw;
    font-weight: 600;
    color: #6d6b6b;
    margin-top: 1vw;
    margin-bottom: 1vw;
    display: flex;
    justify-content: center;
}

.panteones ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-top: 38vw;
}

.panteones-h4 {
    font-size: 1.5vw;
    font-weight: 600;
    color: #6d6b6b;
    margin-top: 1vw;
    margin-bottom: 1vw;
    display: flex;
    justify-content: center;
}

.pilar{
    width: 37vw;
    height: 25vw;
    border: 1px solid rgb(177 165 165);
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 75%);
    margin-right: 3vw;

}

.lujan{
    width: 36vw;
    height: 22vw;
    border: 1px solid rgb(177 165 165);
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 75%);
}

.otra{
    width: 36vw;
    height: 26vw;
    border: 1px solid rgb(177 165 165);
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 75%);
    margin-right: 3vw;
}

.lista-left{
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 40% 60%;
    margin-top: 1vw;
    width: 94.9vw;
}

.img-left{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    
   
  
}

.lista-right{
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: 60% 40%;
        margin-top: 1vw;
        width: 94.9vw;
    
}

.lista-right-mobile{
 display: none;

}

.img-right{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 3vw;
}

.text-cont-left-solo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    font-size: 1.5vw;
    font-weight: 600;
    color: #6d6b6b;
    margin-left: 3vw;
}

.text-cont-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1.5vw;
    font-weight: 600;
    color: #6d6b6b;
    text-align: justify;
    margin-right: 3vw;

}

.texto-right{
    font-size: 1.1vw;
    font-weight: 300;
    color: #807f7f;
    text-align: justify;
    
}

.text-cont-left{       
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1.5vw;
    font-weight: 600;
    color: #6d6b6b;
    text-align: justify;
    margin-left: 3vw;
 
}

.texto-left{
    font-size: 1.1vw;
    font-weight: 300;
    color: #807f7f;
    text-align: justify;
    
}

/* -----------------------MOBILE----------------------- */    

@media screen and (width <= 768px) {
    .main-sep{
        height: auto;
    }

    .sepelios-cont{
        margin-top: 25vw;
        height: auto;
        border: 2px solid #918d8d;
        box-shadow: 0 0 5px 0 rgb(0 0 0 / 75%);
       display: flex;
       flex-direction: column;
            
        }

        .sepelios-left{
            height:100vw;
            border-radius: 10px;
            animation: appear-from-left 1s;
        }
        
        .sepelios-left h3{
            font-size: 5vw;
            padding: 0;
          margin: 1vw 40vw;
        }
        
        .sepelios-left p{
            font-size: 2.8vw;
            margin: 0;
            padding: 0;
            margin-left: 1vw;
            margin-top: 1vw;
            color: rgb(116 107 107);
            font-weight: 400;
        }
        
        .sepelios-left li{
            font-size: 2.5vw;
            margin: 0;
            padding: 0;
            margin-left: 1vw;
            margin-top: 1vw;
            color: rgb(116 107 107);
            font-weight: 400;
        }

        .norte{
            height: 70vw;
            width: 90vw;
            margin-top: 110vw;
            margin-left: 4vw;
            border: 1px solid rgb(177 165 165);
            border-radius: 5px;

        }

        .panteones{
            height:350vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            justify-items: center;  
            width: 95%;
             margin: auto;
            margin-top: 1vw;
            
         
        }

        .panteones-title{
            position: relative;
            top: -105vw;
            font-size: 4.5vw;
            font-weight: 600;
            color: #6d6b6b;
            text-align: center;
        }

        .panteones-h4 {
            position: relative;
            top: -100vw;
            font-size: 3.8vw;
            font-weight: 600;
            color: #6d6b6b;
            text-align: center;
        
        }

        .lista-left{
           display: flex;
              flex-direction: column;
                justify-content: center;
                align-items: center;
               
        }

        .left-h3{
            font-size: 3.2vw;
            font-weight: 600;
            color: #6d6b6b;
            display: flex;
            justify-content: center;
            text-align: center;

        }

        .pilar{
            width: 90vw;
            height: 60vw;
            border: 1px solid rgb(177 165 165);
            border-radius: 10px;
            box-shadow: 0 0 5px 0 rgb(0 0 0 / 75%);
            margin-right: 3vw;
            margin-top: 3vw;
        
        }

        .lista-right{
            display: none;
        }

        .lista-right-mobile{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 3vw;
        }

        .text-cont-right{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            font-size: 3vw;
            font-weight: 600;
            color: #6d6b6b;
            text-align: center;
            margin-right: 3vw;
        
        }

        .texto-right{
            font-size: 2.5vw;
            font-weight: 300;
            color: #807f7f;
            text-align: justify;
            margin: 2vw;

            

        }

        .lujan{
            width: 90vw;
            height: 50vw;
            border: 1px solid rgb(177 165 165);
            border-radius: 10px;
            box-shadow: 0 0 5px 0 rgb(0 0 0 / 75%);
            margin-top: 3vw;
        }

    .otra{
        width: 90vw;
        height: 60vw;
        border: 1px solid rgb(177 165 165);
        border-radius: 10px;
        box-shadow: 0 0 5px 0 rgb(0 0 0 / 75%);
        margin-right: 3vw;
        margin-top: 3vw;
    }

    .texto-left{
        font-size: 2.5vw;
        font-weight: 300;
        color: #807f7f;
        text-align: justify;
        margin: 2vw;
    }
        
}